<template>
  <div class="settings-wrapper">
    <div class="left-menu">
      <div
        class="menu-item"
        :class="{ active: current?.id === menu.id }"
        @click="onMenuClick(menu)"
        v-for="menu in tree"
        :key="menu.id"
      >
        {{ menu.label }}
      </div>
    </div>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { useIsAdmin } from '@/hooks'
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getModelOpenList } from '@/apis/sys'
import * as TYPE from '@/store/types'
import { codes } from '@/constants'
export default defineComponent({
  name: 'settings',
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      tree: [
        { id: 1, label: '设置主管理员', name: '主管理员', routeName: 'settingAccountMain', type: 'main' },
        { id: 2, label: '设置子管理员', name: '子管理员', routeName: 'settingAccountChild', type: 'child' },
        { id: 3, label: '企业微信', name: '企业微信 ', routeName: 'enterpriseWeChat', type: 'child' },
        { id: 4, label: '公众号', name: '公众号 ', routeName: 'officialAccount', type: 'child' },
        { id: 5, label: '小程序', name: '小程序', routeName: 'applet', type: 'child' },
        { id: 6, label: '用户管理', name: '用户管理', routeName: 'userManager', type: 'child' },
        { id: 9, label: '调查问卷设置', name: '调查问卷设置', routeName: 'questionnaire', type: 'child' }
        // { id: 7, label: '商户号', name: '商户号', routeName: 'merchantManager', type: 'child' }
      ]
    })
    //获取模块控制显示列表

    const isAdmin = useIsAdmin()
    if (isAdmin.value) {
      state.tree.push({
        id: 7,
        label: '解散企业',
        name: '解散企业',
        routeName: 'Dissolution',
        type: 'child'
      })
    }
    const current = computed(() => {
      let temp = {}
      state.tree.forEach(item => {
        if (item.routeName === route.name || item.routeName === route.meta.group) {
          temp = item
        }
      })
      return temp
    })
    const onMenuClick = data => {
      router.push({ name: data.routeName, params: { type: data.type } })
    }
    getModelOpenList({ companyNo: store.state.userInfo.companyNo })
      .then(res => {
        console.log('res: ', res)
        store.commit(TYPE.MODEL_OPEN_LIST, res.data)
        if (res.data.length > 0 && res.data.includes(codes.payment)) {
          //有缴费模块就显示商户号
          state.tree.push({ id: 8, label: '商户号', name: '商户号', routeName: 'merchantManager', type: 'child' })
        }
      })
      .catch(err => {
        console.log('err: ', err)
      })
    return { ...toRefs(state), onMenuClick, current }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
